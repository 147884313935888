import React from 'react';

function Notice() {
  const menu = {width:"20px", height:"20px"};
  const white = {color: "#fff"};
  
  return (
    <div id="notice">
    </div>
  );
}

export default Notice;