import React from 'react';

import Nft from '../components/Nft';
import Mynft from '../components/Mynft';
import SAFUs from '../components/SAFUs';
import Staking from '../components/Staking';

function NftPage() {
  return (
    <div>
      <Nft />
      <Mynft />
    </div>
 
  );
}

export default NftPage;